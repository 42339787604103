import React from "react"
import ImageMeta from "../../components/ImageMeta"
import NuvoImage from "../../components/NuvoImage"
import Button from "@bit/azheng.joshua-tree.button"

import SocialReviews from "../../components/SocialReviews/SocialReviews"
import Stars from "../../components/SocialReviews/Stars"
import ContentColumn from "./ContentColumn"

function TopSection(props) {
  // if (props.post.hasImages) {
    return (
      <div className="columns is-desktop top-section doctor">
        <Button
          buttonText={props.language === "es" ? "ATRÁS" : "BACK"}
          goBack
          href={
            props.language === "es" ? "/es/acerca-de-nosotros/" : props.backUrl
          }
        />

        <div className="column is-1"></div>
        <div className="column doctor-image-wrapper is-11">
          <NuvoImage
            useAR
            cloudName="nuvolum"
            publicId={props.post.cutoutImage}
            width="auto"
          ></NuvoImage>
        </div>
        <div className="column is-1"></div>

        <ContentColumn
          heading={props.post.heading}
          topBlurb={props.post.topBlurb}
          badge={props.post.badge}
          language={props.language}
        />
        <div className="column"></div>
      </div>
    )
  // }
  // return (
  //   <>
  //     <div
  //       className="columns has-text-centered top-section doctor is-vcentered"
  //       style={{ backgroundColor: "#fff" }}
  //     >
  //       <Button
  //         buttonText={props.language === "es" ? "ATRÁS" : "BACK"}
  //         goBack
  //         href={
  //           props.language === "es" ? "/es/acerca-de-nosotros/" : props.backUrl
  //         }
  //       />

  //       <div className="column is-5"></div>
  //       <div className="column">
  //         <h1>{props.post.heading}</h1>
  //         <p className="large-doctor-text large">{props.post.topBlurb}</p>
  //       </div>
  //       <div className="column is-5"></div>
  //     </div>
  //     {/* <div className="columns" style={{ marginTop: "44px" }}>
  //       <div className="column is-offset-2 is-20">
  //         <div className="hl"></div>
  //       </div>
  //     </div> */}
  //   </>
  // )
}

export default TopSection
